<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    fill="#1b26e2"
    class="dropShadow"
    viewBox="0 0 52 52"
    enable-background="new 0 0 52 52"
    xml:space="preserve"
  >
    <path
      d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M39.4,20L24.1,35.5
	c-0.6,0.6-1.6,0.6-2.2,0L13.5,27c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2c0.6-0.6,1.6-0.6,2.2,0l4.4,4.5c0.4,0.4,1.1,0.4,1.5,0L35,15.5
	c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C40.1,18.3,40.1,19.3,39.4,20z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.dropShadow {
  filter: drop-shadow(2px 2px 2px #202020);
}
</style>
