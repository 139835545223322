<template>
  <div id="map">
    <!--In the following div the HERE Map will render-->
    <div id="mapContainer" ref="hereMap"></div>
  </div>
</template>

<script>
export default {
  name: "HereMap",
  props: {
    center: Object,
    zoom: Number,
  },
  data() {
    return {
      platform: null,
      map: null,
      place: false,
    };
  },
  async mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: process.env.VUE_APP_HERE_API_KEY,
    });
    this.platform = platform;
    this.initializeHereMapOriginal();
  },
  methods: {
    initializeHereMapOriginal() {
      let latVar = this.center.lat;
      let lngVar = this.center.lng;
      // rendering map
      let mapContainer = this.$refs.hereMap;
      let H = window.H;
      // Obtain the default map types from the platform object
      let maptypes = this.platform.createDefaultLayers({
        lg: "cs",
      });

      // Instantiate (and display) a map object:
      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: this.zoom,
        center: this.center,
        // center object { lat: 40.730610, lng: -73.935242 }
      });

      addEventListener("resize", () => this.map.getViewPort().resize());
      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
      // add UI
      H.ui.UI.createDefault(this.map, maptypes);
      // Create a custom marker:
      let marker = new H.map.DomMarker({ lat: latVar, lng: lngVar });
      // Add the marker to the map:
      if (latVar !== 0 && lngVar !== 0) {
        this.map.addObject(marker);
      }
      // End rendering the initial map
    },
  },
};
</script>

<style scoped>
#map {
  display: flex;
  flex-direction: column;
}
#mapContainer {
  height: 300px;
}
</style>
