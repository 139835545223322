<template>
  <div>
    <section v-if="currentProperty.name">
      <div class="max-w-screen-lg mx-auto px-4">
        <div class="grid">
          <div class="grid">
            <div class="grid z-20" style="height: 480px">
              <!-- GALERIE -->
              <div class="w-full z-10" style="height: 480px">
                <div v-if="enablePreview && selectedCarouselItem" class="flex justify-center">
                  <a v-if="selectedCarouselItem.type === 'image'" target="_blank" :href="selectedCarouselItem.src">
                    <img style="height: 480px" :src="selectedCarouselItem.src" />
                  </a>
                  <a v-else-if="selectedCarouselItem.type === 'video'" target="_blank" :href="selectedCarouselItem.src">
                    <video width="480" controls>
                      <source :src="selectedCarouselItem.src" type="video/mp4" />
                    </video>
                  </a>
                </div>
                <div v-if="mounted && (dataImages.length || dataVideos.length)" class="flex justify-center">
                  <a v-if="dataVideos.length > 0" target="_blank" :href="dataVideos[0].videos">
                    <video width="480" controls>
                      <source :src="dataVideos[0].videos" type="video/mp4" />
                    </video>
                  </a>
                  <a v-else target="_blank" :href="dataImages[0].images">
                    <div>
                      <img style="height: 480px" :src="dataImages[0].images" />
                    </div>
                  </a>
                </div>
                <router-link v-if="!enablePreview" class="flex justify-center" :to="'priprava-nemovitosti'">
                  <a target="_blank">
                    <img style="height: 480px" src="../../../public/images/photodefaultportrait.png" />
                  </a>
                </router-link>
              </div>
            </div>
            <!-- VYBER OBRAZKU -->
            <div class="w-full z-20 bg-gray-50 flex justify-center overflow-auto">
              <div v-if="enablePreview && (dataImages || dataVideos)" class="flex items-center">
                <div v-for="video in dataVideos" :key="video.id" v-on:click="chooseMediaHandler(video, 'video')">
                  <video class="opacity-90 hover:opacity-100 duration-200 cursor-pointer" width="64" height="64">
                    <source :src="video.videos" type="video/mp4" />
                  </video>
                </div>

                <div v-for="image in dataImages" :key="image.id" v-on:click="chooseMediaHandler(image, 'image')">
                  <div class="flex-1 w-16">
                    <img style="border: 1px solid white"
                      class="mx-auto my-auto h-16 opacity-90 hover:opacity-100 duration-200 cursor-pointer"
                      :src="image.images" />
                  </div>
                </div>
              </div>
              <div v-else>
                <img style="border: 1px solid white"
                  class="mx-auto my-auto h-16 opacity-90 hover:opacity-100 duration-200 cursor-pointer"
                  src="../../../public/images/photodefaultportrait.png" />
              </div>
            </div>
          </div>
          <div class="block bg-white z-30">
            <div class="my-4 text-body-1 font-bold text-15">
              Objednané služby
            </div>
            <div v-if="
              (!loading && !receiptsByPropertyId.length) ||
              isSessionPropertyId === `no services`
            " class="font-bold text-md text-body-1 mt-2">
              <div @click="switcher = !switcher" v-if="switcher">
                <button
                  class="text-red-600 w-full px-4 text-left bg-red-100 hover:opacity-60 border-1 font-bold rounded border-2 border-red-200 focus:outline-none mr-4">
                  Nemáte zakoupeny žádné služby. Přejete si nějakou zakoupit?
                </button>
              </div>
              <div v-if="!switcher">
                <router-link :class="switcherCss" :to="{ name: 'strategie' }">Strategie a dokumentace</router-link>
                <router-link :class="switcherCss" :to="{ name: 'priprava-nemovitosti' }">Příprava
                  nemovitosti</router-link>
                <router-link :class="switcherCss" :to="{ name: 'inzerce' }">Inzerce</router-link>
                <router-link :class="switcherCss" :to="{ name: 'pravni-sluzby' }">Právní služby</router-link>
              </div>
            </div>
            {{/*Loaded, services are found */ }}
            <div v-if="
              !loading &&
              receiptsByPropertyId.length &&
              isSessionPropertyId !== `no services`
            ">
              <div v-for="receipt in receiptsByPropertyId" :key="receipt.id">
                <div 
                  class="mt-2 w-full h-11 px-4 text-left leading-10 border-1 font-bold rounded border-2"
                  :class="(receipt === '1x Základní inzerce' && !advert) ? 'bg-red-400 border-red-700 flex justify-between' : 'bg-green-50 border-green-200'"  
                >
                  {{ receipt }}
                  <button v-if="receipt === '1x Základní inzerce' && !advert"
                    class="create-advertise"
                    @click="portalModal()"
                  >
                    Vytvořit inzerát
                  </button>
                </div>
              </div>
            </div>
            <!-- PortalModal -->
            <div v-if="portalOpen" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
              aria-modal="true">
              <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-md leading-6 font-bold text-body-1 select-none" id="modal-title">
                          {{ modalText }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex"
                    :class="modalText === 'Vaše nemovitost má méně než 4 fotky!' || modalText === 'Vaše nemovitost má více než 8 fotek!' ? 'sm:flex-row' : ' sm:flex-row-reverse justify-between'">
                    <button @click="addToPortal(portalName)" type="button"
                      class="mt-3 focus:outline-none w-full inline-flex rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                      :class="{
                        hidden: modalText === 'Vaše nemovitost má méně než 4 fotky!' ||
                          modalText === 'Vaše nemovitost má více než 8 fotek!'
                      }">
                      Ano
                    </button>
                    <button @click="
                      [
                        (portalOpen = !portalOpen),
                        (advertSrealityPass = false),
                        (advertIdnesPass = false),
                      ]
                    " type="button"
                      class="mt-3 focus:outline-none w-full inline-flex rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm">
                      Zrušit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- addAdvertPassed -->
            <div v-if="addAdvertPassed && !advertInDatabase">
              <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                  <div
                    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div>
                        <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
                          <h3 class="text-xl leading-6 font-bold text-body-1 select-none py-4 text-center" id="modal-title">
                            Přidáváme inzerát na inzertní portál {{ portalName }}
                          </h3>
                          <div class="flex justify-center">
                            <div></div>
                            <div>
                              <img src="../../../public/images/dataLoading.gif" />
                            </div>
                            <div></div>
                          </div>
                          <div class="flex justify-center">
                            <div></div>
                            <div class="text-lg text-body-1 pt-2">
                              {{ this.workingOn }}
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Advert remove -->
            <RemoveAdvertModal v-if="removeModalOpen" :property="currentProperty" v-on:update-data="updateDataHandler($event)"
              v-on:close="removeModalOpen = false" />
            <UpdateAdvertModal v-if="updateModalOpen" v-on:close="updateModalOpen = false" />

            <div v-if="loading" class="font-bold text-md text-body-1 mt-2">
              <div class="inline-flex">
                <img src="../../../public/images/dataLoading.gif" />
              </div>
            </div>
            <div class="text-4xl font-bold text-body-4 my-8">
              {{ currentProperty.name }}
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class>
                <h2 class="text-body-4 text-4xl">
                  {{ propertyDispositionHandler() }}
                  {{ areaController(currentProperty.usableAreaApartment) }}
                </h2>
              </div>
              <div class="grid grid-rows-2">
                <div class="justify-self-start md:justify-self-end mt-3 md:mt-0">
                  <h2 @click="handleYourPrice()" class="text-4xl text-body-1 font-bold select-none">
                    {{ serialize(currentProperty.propertyPrice) }}
                  </h2>
                </div>
                <div class="mt-2 justify-self-start md:justify-self-end">
                  <!-- <h2 class="text-15 text-body-4 font-bold">
                Hypotéka od 12 000 Kč
              </h2>-->
                </div>
              </div>
              <div>
                <h2 class="text-2xl md:text-3xl text-body-4 font-medium">
                  {{ isEmpty(currentProperty.address) }}
                  {{ isEmpty(currentProperty.city) }}
                  {{ currentProperty.zipCode }}
                </h2>
              </div>
            </div>
            <div v-if="
              currentProperty.propertyDescription &&
              currentProperty.propertyDescription !== 'undefined'
            " class="mt-12">
              <!-- <p class="md:text-2xl md:font-bold">Popis nemovitosti</p> -->
              <p class="text-17 text-body-4">
                {{ currentProperty.propertyDescription }}
              </p>
            </div>
            <div v-if="
              currentProperty.dispositionViewDescription.length > 0 &&
              currentProperty.dispositionViewDescription !== 'undefined'
            " class="mt-12">
              <!-- <p class="md:text-2xl md:font-bold">Popis z pohledu dispozice</p> -->
              <p class="text-17 text-body-4 text-red-800">
                {{ currentProperty.dispositionViewDescription }}
              </p>
            </div>
            <div v-if="
              currentProperty.surroundingDescription.length > 0 &&
              currentProperty.surroundingDescription !== 'undefined'
            " class="mt-12">
              <!-- <p class="md:text-2xl md:font-bold">
            Popis okolí a občanské vybavenosti
          </p> -->
              <p class="text-17 text-body-4">
                {{ currentProperty.surroundingDescription }}
              </p>
            </div>
            <div v-if="
              currentProperty.outsideViewDescription.length > 0 &&
              currentProperty.outsideViewDescription !== 'undefined'
            " class="mt-12">
              <!-- <p class="md:text-2xl md:font-bold">Popis z venkovního pohledu</p> -->
              <p class="text-17 text-body-4">
                {{ currentProperty.outsideViewDescription }}
              </p>
            </div>
            <div v-if="
              currentProperty.energyAndAdditionalDescription.length > 0 &&
              currentProperty.energyAndAdditionalDescription !== 'undefined'
            " class="mt-12">
              <!-- <p class="md:text-2xl md:font-bold">
            Popis z energetických a doplňujících informací
          </p> -->
              <p class="text-17 text-body-4">
                {{ currentProperty.energyAndAdditionalDescription }}
              </p>
            </div>
            <!-- EDITOVATELNY OBSAH -->
            <div class="mt-12 text-body-1 font-bold text-15">
              <span style="cursor: pointer" @click="editingProperty()">
                <p>Upravit popis nemovitosti</p>
                <p class="pt-4">
                  <Edit />
                </p>
              </span>
            </div>
            <div v-if="editing === true">
              <EditPropertyModal v-on:onAllDataLoaded="onAllDataLoaded($event)" v-on:editing="editing"
                v-on:onClickOutsideParent="onClickOutsideParent($event)" />
            </div>
            <!-- ↑↑ EDITOVATELNY OBSAH ↑↑ -->
            <div v-if="editSuccess">
              <SuccessModal />
            </div>
            <div class="mt-12">
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="grid grid-cols-2">
                  <div class="grid grid-rows-10">
                    <div class="my-4 text-body-1 font-bold text-15">
                      Základní informace
                    </div>
                    <div class="text-17 font-bold text-body-4">
                      Typ nemovitosti
                    </div>
                    <div class="text-17 font-bold text-body-4 pt-3">
                      Dispozice
                    </div>
                    <div class="text-17 font-bold text-body-4 pt-3">Stav</div>
                    <div class="text-17 font-bold text-body-4 pt-3">Adresa</div>
                    <div class="text-17 font-bold text-body-4 pt-3">Město</div>
                    <div class="text-17 font-bold text-body-4 pt-3">
                      Vlastnictví
                    </div>
                    <div class="text-17 font-bold text-body-4 pt-3">
                      Užitná plocha
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('1')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Balkón / Lodžie
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('2')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Terasa
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('3')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Lodžie
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('4')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Sklep
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('5')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Parkování
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('6')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Výtah
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('7')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Garáž
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('8')"
                      class="text-17 font-bold text-body-4 pt-3">
                      Klimatizace
                    </div>
                  </div>

                  <div class="grid grid-rows-10">
                    <div class="my-4 text-body-1 font-bold text-15 pt-6"></div>
                    <div class="text-17 text-body-4">
                      {{
                        $const.PROPERTY_TYPES[currentProperty.propertyType - 1]
                          .name
                      }}
                    </div>
                    <div class="text-17 text-body-4 pt-3">
                      {{ propertyDispositionHandler() }}
                    </div>
                    <div class="text-17 text-body-4 pt-3">
                      {{
                        $const.PROPERTY_STATES[
                          currentProperty.propertyState - 1
                        ].name
                      }}
                    </div>
                    <div class="text-17 text-body-4 pt-3">
                      {{ currentProperty.address }}
                    </div>
                    <div class="text-17 text-body-4 pt-3">
                      {{ isEmpty(currentProperty.city) }}
                      {{ currentProperty.zipCode }}
                    </div>
                    <div class="text-17 text-body-4 pt-3">
                      {{
                        $const.PROPERTY_OWNERSHIPS[
                          currentProperty.propertyOwnership - 1
                        ].name
                      }}
                    </div>
                    <div class="text-17 text-body-4 pt-3">
                      {{ areaController(currentProperty.usableAreaApartment) }}
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('1')" class="text-17 text-body-4 pt-3">
                      {{ isOtherUsableArea(currentProperty.usableAreaBalcony) }}
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('2')" class="text-17 text-body-4 pt-3">
                      Ano
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('3')" class="text-17 text-body-4 pt-3">
                      Ano
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('4')" class="text-17 text-body-4 pt-3">
                      {{ isOtherUsableArea(currentProperty.usableAreaCellar) }}
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('5')" class="text-17 text-body-4 pt-3">
                      Ano
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('6')" class="text-17 text-body-4 pt-3">
                      Ano
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('7')" class="text-17 text-body-4 pt-3">
                      Ano
                    </div>
                    <div v-if="currentProperty.propertyAdditional.includes('8')" class="text-17 text-body-4 pt-3">
                      Ano
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="text-body-1 text-15 font-bold my-4 mt-8">
                Energetická třída
              </h2>
              <div :style="{ borderColor: energyClassColor }" class="border-2 mt-4">
                <EnergyClass :color="energyClassColor" :value="currentProperty.energyClass" />
              </div>
            </div>
            <div v-if="openMap && currentProperty" class="mt-6">
              <h2 class="text-body-1 text-15 font-bold my-4">Mapa</h2>
              <HereMap :center="center" :zoom="18" />
            </div>

            <div class="flex justify-between my-8">
              <router-link :to="encodedUri" target="_blank">
                <button
                  class="modal-open hover:opacity-80 px-4 bg-body-1 h-12 text-white rounded-md focus:outline-none border-2 border-body-1">
                  Zobrazit náhled inzerce
                </button>
              </router-link>
              <button class="px-8 font-bold text-15 bg-body-3 text-white focus:outline-none rounded-md h-12"
                @click="save()">
                Pokračovat na služby
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="loading">
      <div class="mx-auto mb-36">
        <img src="../../../public/images/noProject.png" class="block px-4 mx-auto mt-12 md:mt-24" />
        <img class="block px-4 mx-auto mt-20 w-32" src="../../../public/images/elipse.gif" />
      </div>
    </section>

    <section v-if="!currentProperty.id && !loading && properties.length < 1">
      <div class="mx-auto">
        <img src="../../../public/images/noProject.png" class="block px-4 mx-auto mt-12 md:mt-24" />
        <h2 class="text-body-4 text-4xl mx-4 text-center font-bold">
          Zatím žádnou nemovitost neprodáváte
        </h2>
        <p class="text-body-4 text-17 max-w-md mx-auto text-center opacity-90 mt-6">
          Je to jednoduché. Stačí chvíle a budete prodávat nemovitost jako
          profík. Tak do toho.
        </p>
        <div class="text-center">
          <button
            class="h-16 px-8 my-12 md:mt-24 rounded-md bg-body-3 hover:opacity-70 duration-300 text-white focus:outline-none"
            @click="$router.push('/pridat-nemovitost')">
            Vytvořit nemovitost
          </button>
        </div>
      </div>
    </section>

    <section v-if="!currentProperty.id && !loading && properties.length">
      <div class="mx-auto mb-36">
        <img src="../../../public/images/noProject.png" class="block px-4 mx-auto mt-12 md:mt-24" />
        <h2 class="text-body-4 text-4xl mx-4 text-center font-bold">
          Vyberte prosím Vaši nemovitost
        </h2>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import { getMyTerm } from "@/graphql/queries/userTermsQueries.js";
import { SET_CURRENT_PROPERTY } from "@/store/main/constants";
import Edit from "../../components/svg/Edit.vue";
import EnergyClass from "../../components/svg/EnergyClass.vue";
import SuccessModal from "../../components/modals/SuccessModal.vue";
import EditPropertyModal from "../../components/dashboard/EditPropertyModal.vue";
import {
  getImages,
  getProperties,
  getStripe,
  getAdverts,
  getVideos,
} from "../../services/rest/services";
import HereMap from "../../components/map/HereMap.vue";

export default {
  components: {
    HereMap,
    Edit,
    EnergyClass,
    EditPropertyModal,
    SuccessModal,
  },
  computed: {
    ...mapState("main", ["contracts", "currentProperty"]),
    ...mapState("user", ["user", "loggedIn"]),
  },
  data() {
    return {
      encodedUri: "",
      advert: false,
      editSuccess: false,
      editing: false,
      openMap: false,
      center: {
        lat: null,
        lng: null,
      },
      image: 0,
      energyClassColor: "",
      imageSelected: 1,
      receipts: [],
      loaded: false,
      myTerms: [],
      isLoaded: false,
      sessions: [],
      isSessionPropertyId: [],
      sessionsPaymentIntentArray: [],
      receiptsByPropertyId: [],
      loading: true,
      switcher: true,
      setRules: true,
      properties: [],
      dataImages: [],
      dataVideos: [],
      dataFiles: [],
      enablePreview: true,
      mounted: true,
      selectedCarouselItem: null,
      showInfoPrice: false,
      allDataLoaded: false,
      switcherCss:
        "text-body-1 mt-2 w-full h-11 px-4 text-left bg-blue-100 hover:opacity-60 leading-10 border-1 font-bold rounded border-2 border-blue-200 focus:outline-none mr-4",
      portalOpen: false,
      portalName: "",
      modalText: "Přejete si přidat nemovitost na inzertní portály?",
      addAdvertPassed: false,
      advertInDatabase: false,
      advertSrealityPass: false,
      advertIdnesPass: false,
      generatingUrlLink: false,
      workingOn: "",
    };
  },
  methods: {
    ...mapMutations("main", {
      setCurrentPropertyStore: SET_CURRENT_PROPERTY,
    }),
    editingProperty() {
      setTimeout(() => {
        this.editing = true;
      }, 200);
    },
    onClickOutsideParent() {
      if (this.editing == true) {
        this.editing = false;
      }
    },
    async onAllDataLoaded() {
      await getProperties().then((res) => {
        res.forEach(async (property) => {
          if (property.id === this.currentProperty.id) {
            this.$store.commit("main/SET_CURRENT_PROPERTY", property);
            localStorage.setItem("currentProperty", property);
            this.editSuccess = true;
            setTimeout(() => {
              this.editSuccess = false;
            }, 2000);
            await this.propertyService();
          }
        });
      });
    },
    handleYourPrice() {
      this.$router.push("strategie");
    },
    async imageService() {
      this.dataImages = await getImages(this.currentProperty.id);
      this.dataVideos = await getVideos(this.currentProperty.id);
      if (!this.dataImages.length && !this.dataVideos.length) {
        this.enablePreview = false;
      }
    },
    async propertyService() {
      this.properties = await getProperties();
    },
    propertyDispositionHandler() {
      if (this.currentProperty.propertyDisposition === 1) {
        return "Garsonka";
      } else if (this.currentProperty.propertyDisposition === 2) {
        return "1+kk";
      } else if (this.currentProperty.propertyDisposition === 3) {
        return "1+1";
      } else if (this.currentProperty.propertyDisposition === 4) {
        return "2+kk";
      } else if (this.currentProperty.propertyDisposition === 5) {
        return "2+1";
      } else if (this.currentProperty.propertyDisposition === 6) {
        return "3+kk";
      } else if (this.currentProperty.propertyDisposition === 7) {
        return "3+1";
      } else if (this.currentProperty.propertyDisposition === 8) {
        return "4+kk";
      } else if (this.currentProperty.propertyDisposition === 9) {
        return "4+1";
      } else if (this.currentProperty.propertyDisposition === 10) {
        return "5+kk";
      } else if (this.currentProperty.propertyDisposition === 11) {
        return "5+1";
      } else if (this.currentProperty.propertyDisposition === 12) {
        return "6+kk a větší";
      }
    },

    chooseMediaHandler(media, mediaType) {
      this.enablePreview = true;
      this.mounted = false;
      this.selectedCarouselItem = {
        src: mediaType === 'image' ? media.images : media.videos,
        type: mediaType,
      };
    },

    async changeTrueData() {
      const thisRenderedProperty = this.properties.find(
        (i) => i.id === this.currentProperty.id
      );
      this.$store.commit("main/SET_CURRENT_PROPERTY", thisRenderedProperty);
    },

    energyHandler() {
      if (this.currentProperty.energyClass === "A") {
        this.energyClassColor = "#318A30";
      } else if (this.currentProperty.energyClass === "B") {
        this.energyClassColor = "#35C436";
      } else if (this.currentProperty.energyClass === "C") {
        this.energyClassColor = "#B7E63E";
      } else if (this.currentProperty.energyClass === "D") {
        this.energyClassColor = "#FFB846";
      } else if (this.currentProperty.energyClass === "E") {
        this.energyClassColor = "#F66E48";
      } else if (this.currentProperty.energyClass === "F") {
        this.energyClassColor = "#EE484A";
      } else if (this.currentProperty.energyClass === "G") {
        this.energyClassColor = "#FF3838";
      }
    },

    async dateHandler() {
      //consultation last date in array
      const consultation = await getMyTerm(this.user.email, "consultation");
      const consultationDate = consultation.data.userTerms.map((i) => i.Date);
      this.myTerms.consultation = consultationDate[consultationDate.length - 1];

      //staging last date in array
      const staging = await getMyTerm(this.user.email, "staging");
      const stagingDate = staging.data.userTerms.map((i) => i.Date);
      this.myTerms.staging = stagingDate[stagingDate.length - 1];

      //photo last date in array
      const photo = await getMyTerm(this.user.email, "photo");
      const photoDate = photo.data.userTerms.map((i) => i.Date);
      this.myTerms.photo = photoDate[photoDate.length - 1];

      //video last date in array
      const video = await getMyTerm(this.user.email, "video");
      const videoDate = video.data.userTerms.map((i) => i.Date);
      this.myTerms.video = videoDate[videoDate.length - 1];

      //model last date in array
      const model = await getMyTerm(this.user.email, "model");
      const modelDate = model.data.userTerms.map((i) => i.Date);
      this.myTerms.model = modelDate[modelDate.length - 1];

      this.isLoaded = true;
    },

    imageSelectHandler(x) {
      if (x > 0) {
        return (this.imageSelected = x);
      }
    },
    isOtherUsableArea(size) {
      if (size === "" || size === undefined || size === null) {
        return `Ne`;
      } else return `Ano, ` + parseInt(size) + `m²`;
    },
    areaController(data) {
      if (
        data === "undefined" ||
        data === undefined ||
        data === null ||
        data === ""
      ) {
        return `Nevyplněno`;
      } else return data + `m²`;
    },
    isEmpty(data) {
      if (
        data === "undefined" ||
        data === undefined ||
        data === null ||
        data === ""
      ) {
        return `Nevyplněno`;
      } else return data + `, `;
    },
    async save() {
      try {
        this.$router.push("/strategie", () => { });
      } catch (error) {
        this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },
    serialize(data) {
      if (data === null) {
        return `Cena na vyžádání`;
      } else
        return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ` Kč`;
    },

    async allDataFetch() {
      this.receipts = await getStripe(this.currentProperty.id);
      this.receiptsByPropertyId = [];
      if (this.receipts.length > 0) {
        this.receipts.forEach((receipt) => {
          this.receiptsByPropertyId.push(receipt.description);
        });
      } else this.receipts = null;
      this.loading = false;
    },

    async addToPortal() {
      const photos = [];
      await axios
        .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-images?_limit=-1`, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        })
        .then((res) => {
          res.data.map((image) => {
            if (
              image.property_id === this.$store.state.main.currentProperty.id
            ) {
              photos.push(image);
            }
          });
        });

      if (photos.length < 4) {
        return (this.modalText = "Vaše nemovitost má méně než 4 fotky!");
      }
      if (photos.length > 8) {
        return (this.modalText = "Vaše nemovitost má více než 8 fotek!");
      }

      const noHtmlTags = /(<([^>]+)>)/gi;
      const { propertyDescription } = this.currentProperty;

      if (noHtmlTags.test(propertyDescription)) {
        return (this.modalText =
          "Váš popis nemovitosti obsahuje nepovolené znaky, jako například znaky <, >, /");
      }
      if (propertyDescription.includes("&")) {
        return (this.modalText =
          "Váš popis nemovitosti obsahuje nepovolený znak &");
      }

      this.addAdvertPassed = true;
      this.advertInDatabase = false;
      await this.sreality();
      await this.idnes();
      this.advertInDatabase = true;
      this.portalOpen = false;
    },

    async portalModal(portalName) {
      this.portalOpen = !this.portalOpen;
      this.portalName = portalName;
    },

    // Sreality
    async sreality() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/properties/sreality/${this.currentProperty.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        );
        if (res.data.addAdvertPassed) {
          this.advertSrealityPass = true;
        }
        this.waitForWriteAdvertUrl();
        return res;
      } catch (e) {
        return e;
      }
    },

    // Idnes
    async idnes() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/properties/idnes/${this.currentProperty.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        );
        if (res.data.passed) {
          this.advertIdnesPass = true;
        }
        this.waitForWriteAdvertUrl();
        return res;
      } catch (e) {
        return e;
      }
    },

    async waitForWriteAdvertUrl() {
      this.generatingUrlLink = false;
      this.workingOn = "Ukládáme váš inzerát do databáze...";

      setTimeout(() => {
        this.workingOn = "Váš inzerát je vkládán na portál...";
      }, 3000);
      setTimeout(() => {
        this.workingOn = "Generuje se odkaz na inzerát...";
      }, 6000);
      setTimeout(async () => {
        this.workingOn = "Váš inzerát je publikován!";
      }, 8000);
      setTimeout(async () => {
        setTimeout(() => {
          this.generatingUrlLink = true;
        }, 8000);
      }, 10000);
    },
  },

  async mounted() {
    if (!this.user) {
      window.localStorage.clear();
      this.$router.push("/", () => { });
    }
    //GET PROPERTY
    await this.propertyService();

    await getAdverts(this.currentProperty.id).then((res) => {
      if (res.length) {
        return (this.advert = true);
      }
    });

    // IF JUST ONE PROPERTY CREATED, IT IS CHOOSEN
    //if (this.properties.length === 1) {
    //  this.$store.commit("main/SET_CURRENT_PROPERTY", this.properties[0]);
    //}

    if (this.properties.length && localStorage.getItem("created")) {
      this.$store.commit(
        "main/SET_CURRENT_PROPERTY",
        this.properties[this.properties.length - 1]
      );
      localStorage.removeItem("created");
    }

    if (this.currentProperty) {
      if (this.currentProperty.longitude && this.currentProperty.latitude) {
        this.center.lng = this.currentProperty.longitude;
        this.center.lat = this.currentProperty.latitude;
        this.openMap = true;
      }
      
      if (this.currentProperty.id) {
        let normalizeName = this.currentProperty.name
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
        this.encodedUri = `inzerce/${normalizeName}/${this.currentProperty.id}`;
      }
    }

    if (!this.$store.state.main.currentProperty) {
      this.$store.commit("main/SET_CURRENT_PROPERTY", this.properties[0]);
    }

    this.energyHandler();

    await this.allDataFetch();
    this.imageService();
    this.allDataLoaded = true;
  },

  watch: {
    async currentProperty() {
      await getAdverts(this.currentProperty.id).then((res) => {
        if (res.length) {
          return (this.advert = true);
        } else return (this.advert = false);
      });
      await this.imageService();
      await this.changeTrueData();
      await this.allDataFetch();
      this.energyHandler();
      this.openMap = false;
      setTimeout(() => {
        this.center.lng = this.currentProperty.longitude;
        this.center.lat = this.currentProperty.latitude;
        this.openMap = true;
      }, 500);
    },
    async $route() {
      await this.imageService();
      await this.changeTrueData();
      await this.allDataFetch();
      this.energyHandler();
    },
    async advertInDatabase(newAdvertValue) {
      if (newAdvertValue) {
        this.advert = true;
        await this.allDataFetch();
      }
    }
  },
};
</script>

<style scoped>
.create-advertise {
  @apply rounded-lg px-5;
  background-color: #1b26e2;
  color: white;
}
</style>