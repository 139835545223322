<template>
  <svg
    class="exit-icon"
    height="32"
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 0l-1.41 1.41.72.72 1.78 1.81-1.78 1.78-.72.69 1.41 1.44.72-.72 1.81-1.81 1.78 1.81.69.72 1.44-1.44-.72-.69-1.81-1.78 1.81-1.81.72-.72-1.44-1.41-.69.72-1.78 1.78-1.81-1.78-.72-.72z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.exit-icon {
  cursor: pointer;
  fill: #1b26e2;
}
.exit-icon:hover {
  filter: drop-shadow(2px 2px 2px #000000);
}
</style>
