import gql from "graphql-tag";
import apolloClient from "@/apolloClient.js";

export const getMyTerm = async (myEmail, type) =>
  await apolloClient.query({
    query: gql`
    query {
      userTerms(where: {Email: "${myEmail}" Type: "${type}"}) {
        id
        Email
        Date
        Type
      }
    }
  `,
  });
