<template>
  <!-- Modal -->
  <div
    class="fixed z-40 inset-0 overflow-hidden"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="text-center sm:block sm:p-0">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
      ></div>
      <div
        v-click-outside="onClickOutside"
        style="height: 640px !important"
        class="mt-32 mx-auto rounded-lg text-left overflow-y-auto shadow-xl transform bg-white md:w-1/2 sm:w-4/5"
      >
        <span
          style="cursor: pointer"
          @click="onClickOutside()"
          class="absolute right-5 z-50 top-5"
        >
          <Exit />
        </span>
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
            <h3
              class="text-xl leading-6 font-bold text-body-1 select-none py-8 text-center"
              id="modal-title"
            >
              <p class="text-body-1">Úprava nemovitosti</p>
            </h3>
            <div class="grid grid-cols-1">
              <div class="pb-4">
                <h3 class="text-body-1 text-15 font-bold">Stav a dispozice</h3>
                <textarea
                  class="h-48"
                  v-model="property.propertyDescription"
                  :class="inputCSS"
                />
                <h3 class="text-body-1 text-15 font-bold pt-4">
                  Okolí a občanská vybavenost
                </h3>
                <textarea
                  class="h-48"
                  v-model="property.surroundingDescription"
                  :class="inputCSS"
                />
                <h3 class="text-body-1 text-15 font-bold pt-4">
                  Konstrukce a technický stav
                </h3>
                <textarea
                  class="h-48"
                  v-model="property.outsideViewDescription"
                  :class="inputCSS"
                />
                <h3 class="text-body-1 text-15 font-bold pt-4">
                  Energetické a doplňující informace
                </h3>
                <textarea
                  class="h-48"
                  v-model="property.energyAndAdditionalDescription"
                  :class="inputCSS"
                />
              </div>
              <button
                :class="[
                  `
                        mx-auto
                        focus:outline-none
                        my-8
                        px-8
                        font-bold
                        text-white
                        h-16
                        hover:opacity-70
                        duration-200
                        rounded-md`,
                  error ? 'bg-red-600' : 'bg-body-3',
                ]"
                @click="register()"
              >
                {{ error ? errorMessage : "Uložit" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { getProperties, updateProperty } from "../../services/rest/services";
import Exit from "../svg/Exit.vue";
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
export default {
  components: {
    Exit,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
  },
  async mounted() {
    await getProperties().then((res) => {
      res.forEach(async (property) => {
        if (property.id === this.currentProperty.id) {
          this.property = property;
          this.property.propertyDescription.trim();
          this.property.surroundingDescription.trim();
          this.property.outsideViewDescription.trim();
          this.property.dispositionViewDescription.trim();
          this.property.energyAndAdditionalDescription.trim();
        }
      });
    });
  },
  data: () => {
    return {
      property: {
        id: "",
        propertyDescription: "",
        surroundingDescription: "",
        outsideViewDescription: "",
        dispositionViewDescription: "",
        energyAndAdditionalDescription: "",
      },
      errorMessage: "",
      error: false,
      inputCSS:
        "w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
    };
  },
  props: {
    editing: Boolean,
  },
  methods: {
    async register() {
      this.error = false;
      if (this.property.propertyDescription === "") {
        this.errorMessage = this.$t("ErrorMessages.NoDescription");
        return (this.error = true);
      }
      if (this.property.propertyDescription.length < 30) {
        this.errorMessage = this.$t("ErrorMessages.BadDescription");
        return (this.error = true);
      }

      this.property.propertyDescription.trim();
      this.property.surroundingDescription.trim();
      this.property.outsideViewDescription.trim();
      this.property.dispositionViewDescription.trim();
      this.property.energyAndAdditionalDescription.trim();

      const { access } = await updateProperty(
        encodeURIComponent(JSON.stringify({
          id: this.property.id,
          propertyDescription: this.property.propertyDescription,
          surroundingDescription: this.property.surroundingDescription,
          outsideViewDescription: this.property.outsideViewDescription,
          dispositionViewDescription: this.property.dispositionViewDescription,
          energyAndAdditionalDescription: this.property.energyAndAdditionalDescription,
        })
      ));
      if (access) {
        this.$emit("onAllDataLoaded");
        this.$emit("onClickOutsideParent");
      }
    },
    onClickOutside() {
      this.$emit("onClickOutsideParent");
    },
  },
};
</script>

<style></style>
