<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="128"
    height="64"
    viewBox="0 0 33.866666 16.933333"
    version="1.1"
    id="svg8"
    inkscape:version="1.0.2-2 (e86c870879, 2021-01-15)"
    sodipodi:docname="energyClass.svg"
  >
    <defs id="defs2" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="3.959798"
      inkscape:cx="40.225353"
      inkscape:cy="71.067074"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      inkscape:document-rotation="0"
      showgrid="true"
      units="px"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      inkscape:window-x="1912"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
    >
      <inkscape:grid
        type="xygrid"
        id="grid1402"
        empspacing="8"
        originx="16.933333"
        originy="16.933333"
      />
    </sodipodi:namedview>
    <metadata id="metadata5">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g inkscape:label="Vrstva 1" inkscape:groupmode="layer" id="layer1">
      <path
        :style="[
          `
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
          `,
          { fill: color },
        ]"
        d="M 2.1166663,8.4666663 V 14.816666 H 16.933333 21.166666 L 31.75,8.4666663 l -10.583334,-6.35 H 2.1166663 Z"
        id="path1404"
      />
      <text style="font-size: 10px" x="6" y="12.2" fill="white">
        {{ value }}
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: String,
    value: String,
  },
};
</script>
